import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import downIcon from "../../media/downIcon.png";

const Footer = ({ title, setSelectedField, fields, jobs }) => {
  const [showFields, setShowFields] = useState(false);
  const [showJobs, setShowJobs] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  return (
    <nav className="flex flex-col justify-between gap-5 lg:pt-20 bg-blue-950 text-white">
      <div className="lg:grid lg:grid-cols-4 flex flex-col px-10 lg:px-20 lg:gap-20 items-center lg:items-start lg:justify-center max-w-[1920px] lg:mx-auto">
        <div className="flex items-center justify-center text-lg lg:py-10 w-full">
          <div className="flex flex-col w-full ">
            <h1 className="py-10 lg:pt-0 lg:pb-10 font-bold whitespace-nowrap ">
              {title}
            </h1>
            <h3 className=" pb-2 font-bold"> Contactez-nous!</h3>
            <div className="flex flex-col gap-1 ">
              <span> (514) XXX - XXXX </span>
              <a href="mailto:contact@planbplacement.ca" className="">
                contact@planbplacement.ca
              </a>
            </div>
            <div className="flex flex-col py-5 pb-10 lg:py-10 ">
              <h3 className="text-lg pb-2 font-bold">Heures d'ouverture:</h3>
              <div className="flex flex-col gap-1 ">
                <ul>
                  <li> Lundi au vendredi - de 8h à 20h</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {window.innerWidth <= 640 && (
          <div
            className={`relative  opacity-80 hover:opacity-100  w-full  ${
              showFields ? "rounded-t" : "rounded"
            }`}
          >
            <button
              onClick={() => setShowFields(showFields ? false : true)}
              className={`flex items-center justify-between p-1 w-full border-b  text-white  transition-all `}
            >
              <span className="text-base">Secteurs</span>
              <img src={downIcon} className="w-7 h-7" />
            </button>
            {showFields && (
              <div className="  z-10 w-full text-white py-2 ">
                {fields.map((field, index) => (
                  <div key={index} className="flex w-fit text-start p-1 ">
                    <Link
                      onClick={() => {
                        setSelectedField(field.title);
                      }}
                      to="/emplois"
                      className="hover:text-white hover:font-bold hover:no-underline cursor-pointer"
                    >
                      {field.title}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {window.innerWidth > 640 && (
          <div className="flex items-center justify-center text-lg py-10 w-full">
            <div className="flex flex-col w-full  ">
              <h3 className="text-xl pb-2 font-bold underline"> Secteurs</h3>
              <div className="flex flex-col gap-1 ">
                {fields.map((field, index) => (
                  <div key={index} className="flex w-fit text-start p-1 ">
                    <Link
                      onClick={() => {
                        setSelectedField(field.title);
                      }}
                      to="/emplois"
                      className="hover:text-white hover:font-bold hover:no-underline cursor-pointer"
                    >
                      {field.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {window.innerWidth <= 640 && (
          <div
            className={`relative py-10 opacity-80 hover:opacity-100  w-full  ${
              showJobs ? "rounded-t" : "rounded"
            }`}
          >
            <button
              onClick={() => setShowJobs(showJobs ? false : true)}
              className={`flex items-center justify-between p-1 w-full border-b  text-white  transition-all `}
            >
              <span className="text-base">Emplois récents</span>
              <img src={downIcon} className="w-7 h-7" />
            </button>
            {showJobs && (
              <div className="py-2  z-10 w-full text-white ">
                {jobs
                  .filter((job) => job.status === "open")
                  .slice(0, 7)
                  .map((job, index) => (
                    <div key={index} className="flex w-fit text-center p-1">
                      <a
                        href={`/emplois?jobId=${job.id}`}
                        className="hover:text-white hover:font-bold hover:no-underline cursor-pointer"
                      >
                        {job.title}
                      </a>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        {window.innerWidth > 640 && (
          <div className="flex items-center justify-center text-lg py-10">
            <div className="flex flex-col ">
              <h3 className="text-xl pb-2 font-bold underline">
                Emplois récents
              </h3>
              <div className="flex flex-col gap-1">
                {jobs
                  .filter((job) => job.status === "open")
                  .slice(0, 7)
                  .map((job, index) => (
                    <div key={index} className="flex w-fit text-center p-1">
                      <a
                        href={`/emplois?jobId=${job.id}`}
                        className="hover:text-white hover:font-bold hover:no-underline cursor-pointer"
                      >
                        {job.title}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {window.innerWidth <= 640 && (
          <div
            className={`relative  opacity-80 hover:opacity-100  w-full  ${
              showAbout ? "rounded-t" : "rounded"
            }`}
          >
            <button
              onClick={() => setShowAbout(showAbout ? false : true)}
              className={`flex items-center justify-between p-1 w-full border-b  text-white  transition-all `}
            >
              <span className="text-base">À propos</span>
              <img src={downIcon} className="w-7 h-7" />
            </button>
            {showAbout && (
              <p className="text-justify [text-align-last:center] py-2 ">
                Avec une vision novatrice, Plan B Placement est plus qu'une
                simple agence de placement de personnel. <br /> <br /> Plan B
                Placement s'engage à cultiver un environnement propice à
                l'épanouissement professionnel et personnel de tous ses
                candidats. <br /> <br /> Pour les employeurs, Plan B Placement
                offre une source variée de talents prêts à relever les défis
                actuels et futurs de votre entreprise.
              </p>
            )}
          </div>
        )}

        {window.innerWidth > 640 && (
          <div className="flex items-center justify-center text-lg py-10">
            <div className="flex flex-col">
              <h3 className="text-xl pb-2 font-bold underline">À propos</h3>
              <p className="text-justify w-3/4">
                Avec une vision novatrice, Plan B Placement est plus qu'une
                simple agence de placement de personnel.
                <br /> Plan B s'engage à cultiver un environnement propice à
                l'épanouissement professionnel et personnel de ses candidats.
                <br /> Pour les employeurs, Plan B offre une source variée de
                talents prêts à relever les défis actuels et futurs de votre
                entreprise.
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="relative  w-full m-0 p-5 flex justify-center items-center">
        <p> © Plan B Placement - 2023 </p>
      </div>
    </nav>
  );
};

export default Footer;
