import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import { signOut } from "firebase/auth";

import { NavLink, useNavigate } from "react-router-dom";
import {
  addDoc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  where,
  setDoc,
} from "firebase/firestore";

import Jobs from "./Jobs";
import Messages from "./Messages";
import Members from "./Members";
import downIcon from "../../media/downIcon.png";

const Portal = ({
  jobs,
  fetchJobs,
  fields,
  locations,
  setPopUpMessage,
  setAlertMessage,
}) => {
  const [membersList, setMembersList] = useState([]);
  const [activeMenu, setActiveMenu] = useState("Postes");
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();
  /* let userName = `${userFirstName} ${userLastName[0]}.`;*/
  const signOutHandler = async () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Faciliating members emailing */
  const [newMemberEmail, setNewMemberEmail] = useState("email");
  const [newEmailInput, setNewEmailInput] = useState("");
  const [newMessageTitle, setNewMessageTitle] = useState("");

  const fetchMembers = async () => {
    await getDocs(collection(db, "membres")).then((querySnapshot) => {
      const updatedMembersList = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setMembersList(
        updatedMembersList.filter((member) => member.status !== "Admin")
      );
    });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <div className="py-20 lg:py-40 px-2 lg:px-20 flex flex-col relative justify-center ">
      <div className="flex flex-col  gap-5 items-center justify-between w-full">
        <div className="flex relative justify-between w-full">
          <h4 className=" font-bold text-blue-950 whitespace-nowrap">
            Portail administrateur
          </h4>
          <div
            className={`absolute top-0 right-0 border border-gray-200  bg-white w-2/5 lg:w-1/6 transition-all text-blue-950 rounded z-10`}
          >
            <button
              onClick={() => setShowMenu(showMenu ? false : true)}
              className={`flex items-center font-bold justify-center text-center w-full  bg-white  rounded  lg:p-2  transition-all `}
            >
              <span> {activeMenu} </span>
              <img src={downIcon} className="w-7 h-7" />
            </button>

            {showMenu ? (
              <div className=" grid grid-rows-2  text-blue-950 bg-white  rounded-b">
                <button
                  className={`p-2   bg-white  hover:bg-blue-100 flex justify-center  transition-all ${
                    activeMenu === "Postes" ? "hidden " : " "
                  }`}
                  onClick={() => (setActiveMenu("Postes"), setShowMenu(false))}
                >
                  <span> Postes </span>
                </button>
                <button
                  className={`p-2   bg-white  hover:bg-blue-100 flex justify-center  transition-all ${
                    activeMenu === "Candidats" ? "hidden " : " "
                  }`}
                  onClick={() => (
                    setActiveMenu("Candidats"), setShowMenu(false)
                  )}
                >
                  <span> Candidats </span>
                </button>
                <button
                  className={`p-2   bg-white  hover:bg-blue-100 flex justify-center  transition-all ${
                    activeMenu === "Messagerie" ? "hidden " : " "
                  }`}
                  onClick={() => (
                    setActiveMenu("Messagerie"), setShowMenu(false)
                  )}
                >
                  <span> Messagerie</span>
                </button>
                <button className="text-center py-2 transition-all  hidden">
                  <small>Modifier le mot de passe </small>
                </button>

                <button
                  onClick={signOutHandler}
                  className="p-2   bg-white  hover:bg-gray-100 flex justify-center  transition-all rounded-b"
                >
                  <span>Déconnexion </span>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className=" text-center grid grid-cols-3 w-full lg:w-1/2 gap-2 lg:gap-5  transition-all  "></div>
      </div>

      {activeMenu === "Postes" ? (
        <div>
          <h3 className="py-10 lg:py-20 text-center font-bold whitespace-nowrap">
            Gestion des postes
          </h3>
          <Jobs
            db={db}
            fields={fields}
            setNewEmailInput={setNewEmailInput}
            setNewMessageTitle={setNewMessageTitle}
            setActiveMenu={setActiveMenu}
            jobs={jobs}
            fetchJobs={fetchJobs}
            locations={locations}
            setPopUpMessage={setPopUpMessage}
            setAlertMessage={setAlertMessage}
          />
        </div>
      ) : (
        ""
      )}

      {activeMenu === "Candidats" ? (
        <div>
          <h3 className="py-10 lg:py-20 text-center font-bold whitespace-nowrap">
            Gestion des candidats
          </h3>
          <Members
            db={db}
            membersList={membersList}
            setMembersList={setMembersList}
            setNewEmailInput={setNewEmailInput}
            setActiveMenu={setActiveMenu}
            setPopUpMessage={setPopUpMessage}
          />
        </div>
      ) : (
        ""
      )}
      {/* Messagerie */}
      {activeMenu === "Messagerie" ? (
        <div>
          <h3 className="py-10 lg:py-20 text-center font-bold whitespace-nowrap">
            Messagerie
          </h3>
          <Messages
            db={db}
            membersList={membersList}
            setNewEmailInput={setNewEmailInput}
            setNewMessageTitle={setNewMessageTitle}
            newMessageTitle={newMessageTitle}
            newMemberEmail={newMemberEmail}
            newEmailInput={newEmailInput}
            setNewMemberEmail={setNewMemberEmail}
            setPopUpMessage={setPopUpMessage}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Portal;
