import React, { useState, useEffect } from "react";
import {
  addDoc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  where,
  setDoc,
} from "firebase/firestore";
import { useSearchParams, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import locationIcon from "../../media/locationIcon.png";
import fieldIcon from "../../media/fieldIcon.png";
import scheduleIcon from "../../media/scheduleIcon.png";
import salaryIcon from "../../media/salaryIcon.png";
import idIcon from "../../media/idIcon.png";
import addIcon from "../../media/addIcon.png";
import refreshIcon from "../../media/refreshIcon.png";
import expIcon from "../../media/expIcon.png";
import languageIcon from "../../media/languageIcon.png";
import seeIcon from "../../media/seeIcon.png";
import downloadIcon from "../../media/downloadIcon.png";
import deleteIcon from "../../media/deleteIcon.png";
import modeIcon from "../../media/modeIcon.png";

import Applications from "./Applications";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const Jobs = ({
  db,
  jobs,
  fetchJobs,
  fields,
  locations,
  setPopUpMessage,
  setAlertMessage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [copyOpacity, setCopyOpacity] = useState("opacity-0");

  const [title, setTitle] = useState("");
  const [employer, setEmployer] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [field, setField] = useState("");
  const [schedule, setSchedule] = useState("");
  const [experience, setExperience] = useState("");
  const [mode, setMode] = useState("");
  const [language, setLanguage] = useState("");
  const [description, setDescription] = useState("");

  const [subMenu, setSubMenu] = useState("");

  const [selectedId, setSelectedId] = useState("");
  const [jobSelected, setJobSelected] = useState("");
  const [editId, setEditId] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editEmployer, setEditEmployer] = useState("");
  const [editLocation, setEditLocation] = useState("");
  const [editSalary, setEditSalary] = useState("");
  const [editField, setEditField] = useState("");
  const [editSchedule, setEditSchedule] = useState("");
  const [editExperience, setEditExperience] = useState("");
  const [editMode, setEditMode] = useState("");
  const [editLanguage, setEditLanguage] = useState("");
  const [editDescription, setEditDescription] = useState("");

  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterField, setFilterField] = useState("all");
  const [filterStatus, setFilterStatus] = useState("open");
  const [filterLocation, setFilterLocation] = useState("all");
  const [filteredJobs, setFilteredJobs] = useState();

  const [candidatesJob, setCandidatesJob] = useState("");

  const handleFilterKeywordChange = (e) => {
    setFilterKeyword(e.target.value.toLowerCase());
  };

  const handleFilterFieldChange = (e) => {
    setFilterField(e.target.value);
  };

  const handleFilterStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleFilterLocationChange = (e) => {
    setFilterLocation(e.target.value);
  };

  const handleRefresh = () => {
    setFilterKeyword("");
    setFilterField("all");
    setFilterStatus("open");
    setFilterLocation("all");
    setSearchParams("");
    setEditId("");
    setCandidatesJob("");
    setSelectedId("");
    setSubMenu("");
  };

  const applyFilters = () => {
    let filtered = jobs;
    filtered = filtered.filter(
      (job) => job.status.toLowerCase() === filterStatus.toLowerCase()
    );
    console.log(filtered);
    if (filterKeyword) {
      filtered = filtered.filter(
        (job) =>
          job.id.toLowerCase().includes(filterKeyword) ||
          job.title.toLowerCase().includes(filterKeyword) ||
          job.description.toLowerCase().includes(filterKeyword)
      );
    }

    if (filterField !== "all") {
      filtered = filtered.filter(
        (job) => job.field.toLowerCase() === filterField.toLowerCase()
      );
    }

    if (filterLocation !== "all") {
      filtered = filtered.filter(
        (job) => job.location.toLowerCase() === filterLocation.toLowerCase()
      );
    }

    setFilteredJobs(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [jobs, filterKeyword, filterField, filterStatus, filterLocation]);

  /////////////////////////////////////////////////* JOBS MANAGEMENT *////////////////////////////////////////////////////////

  const [applicationsList, setApplicationsList] = useState([]);

  // Applications list fetch
  const fetchApplications = async (id) => {
    const applicationsQuery = query(collection(db, "candidatures"));
    const querySnapshot = await getDocs(applicationsQuery);

    const updatedApplicationsList = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setApplicationsList(updatedApplicationsList);
  };

  // New job form
  const handleNewJobSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(
        doc(
          db,
          "postes",
          `${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}-${title
            .replace(/ /g, "-")
            .toLowerCase()
            .substring(0, 15)}-${uuid().substring(0, 3)}`
        ),
        {
          id: `${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}-${title
            .replace(/ /g, "-")
            .toLowerCase()
            .substring(0, 15)}-${uuid().substring(0, 3)}`,
          title: title,
          employer: employer,
          location: location,
          salary: salary,
          field: field,
          schedule: schedule,
          experience: experience,
          mode: mode,
          language: language,
          description: description,
          createdAt: new Date().toDateString(),
        }
      );

      setTitle("");
      setEmployer("");
      setLocation("");
      setSalary("");
      setField("");
      setSchedule("");
      setMode("");
      setExperience("");
      setLanguage("");
      setDescription("");
    } catch (e) {
      setAlertMessage("Poste non ajouté. Erreur produite." + e);
    }
  };

  const handleShow = (job) => {
    setSelectedId(job.id);
    setJobSelected(job);
    setSubMenu("view");
  };

  // Job deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "postes", id));
      setPopUpMessage("Poste d'emploi supprimé.");
      setCandidatesJob("");
    } catch (id) {
      setAlertMessage("Erreur, poste d'emploi non supprimé. Id: " + id);
    }
  };

  const handleEdit = (job) => {
    setEditId(job.id);
    setSelectedId("");
    setSubMenu("edit");
    setEditTitle(job.title);
    setEditEmployer(job.employer);
    setEditLocation(job.location);
    setEditSalary(job.salary);
    setEditField(job.field);
    setEditSchedule(job.schedule);
    setEditMode(job.mode);
    setEditExperience(job.experience);
    setEditLanguage(job.language);
    setEditDescription(job.description);
    setCandidatesJob("");
  };

  const handleJobPostEdit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "postes", selectedId), {
        title: editTitle,
        employer: editEmployer,
        location: editLocation,
        salary: editSalary,
        field: editField,
        schedule: editSchedule,
        experience: editExperience,
        mode: editMode,
        language: editLanguage,
        description: editDescription,
        lastModifiedAt: new Date(),
      });
      setPopUpMessage("Poste mise à jour.");
      setSubMenu("view");
      setEditId("");
      setEditTitle("");
      setEditEmployer("");
      setEditLocation("");
      setEditField("");
      setEditSchedule("");
      setEditMode("");
      setEditExperience("");
      setEditLanguage("");
      setEditDescription("");
    } catch (id) {
      setAlertMessage("Erreur, poste non mis à jour.Id: " + id);
    }
  };

  const handleEditCancel = () => {
    setEditId("");

    setSubMenu("");
    setEditTitle("");
    setEditEmployer("");
    setEditLocation("");
    setEditSalary("");
    setEditField("");
    setEditSchedule("");
    setEditMode("");
    setEditExperience("");
    setEditLanguage("");
    setEditDescription("");
  };

  useEffect(() => {
    fetchApplications();
    setFilterStatus("open");
  }, []);

  return (
    <div className="relative ">
      {/*  list filter */}
      <div className="flex flex-col rounded-xl pb-5">
        <div className="flex flex-col lg:flex-row justify-between  gap-5 lg:gap-10 bg-blue-500 p-5 rounded-xl ">
          <div className="grid grid-rows-4 lg:grid-rows-none lg:grid-cols-4 gap-2 lg:gap-10 flex-1 ">
            <input
              placeholder="Titre de poste, mots clés"
              className="w-full flex gap-5 text-center justify-around items-center whitespace-nowrap  rounded p-2 border border-gray-100 "
              value={filterKeyword}
              onChange={handleFilterKeywordChange}
            ></input>

            <select
              className="w-full flex gap-5  justify-around items-center whitespace-nowrap  rounded p-2 border border-gray-100 "
              value={filterField}
              onChange={handleFilterFieldChange}
            >
              <option value="all">Tous les secteurs d'emploi</option>
              {fields.map((field, index) => (
                <option key={index} value={field.title}>
                  {field.title}
                </option>
              ))}
            </select>
            <select
              className="w-full flex gap-5  justify-around items-center whitespace-nowrap  rounded p-2 border border-gray-100 "
              onChange={handleFilterLocationChange}
              value={filterLocation}
            >
              <option value="all"> Tout emplacement </option>
              {locations.map((location, index) => {
                return (
                  <option key={index} value={location}>
                    {location}
                  </option>
                );
              })}
            </select>
            <select
              onChange={handleFilterStatusChange}
              value={filterStatus}
              className="w-full flex gap-5  justify-around items-center whitespace-nowrap  rounded p-2 border border-gray-100 "
            >
              <option value="open"> Postes ouverts </option>
              <option value="closed">Postes fermés</option>
            </select>
          </div>
          <div className="flex gap-5 justify-center lg:w-fit w-full ">
            <button
              className="w-1/2 flex gap-2 p-2 hover:scale-105 justify-center items-center whitespace-nowrap  rounded text-white bg-blue-950 hover:shadow-none  transition-all"
              onClick={handleRefresh}
            >
              <span> Rafraîchir </span>
              <img className="h-6 w-6 " src={refreshIcon} alt="Rafraichir" />
            </button>
            <button
              className="w-1/2 flex gap-2 px-5 hover:scale-105 justify-center items-center whitespace-nowrap  rounded text-white bg-blue-950 hover:shadow-none  transition-all"
              onClick={() => (setSubMenu("add"), setSelectedId(""))}
            >
              <span> Nouveau poste </span>
              <img className="h-6 w-6 " src={addIcon} alt="Ajouter un poste" />
            </button>
          </div>
        </div>
      </div>
      {/* Jobs List */}

      <div className="w-full h-full flex flex-col-reverse lg:grid lg:grid-cols-3 py-10 items-start bg-black bg-opacity-10 shadow-inner rounded-xl">
        <div className=" h-full flex flex-col gap-20 col-span-1  w-full">
          <div className=" h-[500px] lg:h-[900px] gap-10    ">
            <ul className="grid grid-flow-col lg:gap-5 items-center justify-start lg:flex lg:flex-col h-full w-full overflow-x-scroll  lg:overflow-x-hidden lg:overflow-y-scroll py-2 lg:px-10  ">
              {filteredJobs?.map((job) => (
                <li
                  key={job.id}
                  onClick={() => setSelectedId(job.id)}
                  className={`rounded-xl group  w-screen  lg:w-full h-full  px-2 pr-6 lg:p-0 ${
                    selectedId === job.id ? "scale-100  " : "scale-95"
                  } transition-all`}
                >
                  <div
                    className={`p-3 flex items-center justify-between w-full text-white whitespace-nowrap  transition-all 
                    ] rounded-t-lg  ${
                      selectedId === job.id ? "bg-blue-950  " : "bg-blue-500"
                    }`}
                  >
                    <small className="w-3/4 ">{job.field}</small>
                    <small className=" top-2 right-2 text-end ">{job.id}</small>
                  </div>
                  <div className="flex flex-1 flex-col gap-5 p-5 justify-between bg-white">
                    <h4
                      className={` text-blue-500 font-bold transition-all  ${
                        selectedId === job.id ? "text-blue-950  " : ""
                      } ${job.title.length > 30 ? "text-sm  " : ""}`}
                    >
                      {job.title}
                    </h4>

                    <div className="flex justify-between w-full whitespace-nowrap ">
                      <div className=" flex items-center justify-center">
                        <img
                          src={locationIcon}
                          alt="Location"
                          className="w-10 h-10 p-2 opacity-80 group-hover:opacity-100 transition-all"
                        />
                        {job.mode === "Télétravail" ? job.mode : job.location}
                      </div>
                      <div className=" flex items-center justify-center">
                        <img
                          src={scheduleIcon}
                          alt="Horaire"
                          className="w-10 h-10 p-2 opacity-80 group-hover:opacity-100 transition-all"
                        />
                        {job.schedule}
                      </div>
                      {job.salary && (
                        <div
                          className={` flex items-center justify-center ${
                            job.salary === "" ? "invisible" : ""
                          }`}
                        >
                          <img
                            src={salaryIcon}
                            alt="Salaire"
                            className="w-10 h-10 p-2 opacity-80 group-hover:opacity-100 transition-all"
                          />
                          {job.salary === "" ? "À déterminer" : job.salary}
                        </div>
                      )}
                    </div>
                    <hr />
                    <p className="text-justify overflow-y-scroll h-[100px] px-2 ">
                      {`${
                        job.title.length > 50
                          ? job.description.slice(0, 100)
                          : job.description.slice(0, 200)
                      }...`}
                    </p>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-2 justify-center items-center  w-full pb-5 px-5 text-white bg-white rounded-b-xl">
                    <button
                      onClick={() => (
                        setSelectedId(job.id),
                        setCandidatesJob(job),
                        setSubMenu("candidates")
                      )}
                      className="w-full p-2  bg-blue-900 hover:bg-blue-950 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]  transition-all"
                    >
                      Candidats (
                      {applicationsList
                        ? applicationsList.filter(
                            (application) => application.jobId === job.id
                          ).length
                        : 0}
                      )
                    </button>
                    <button
                      onClick={() => handleEdit(job)}
                      className=" w-full p-2  bg-blue-500  rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]  hover:bg-blue-600 transition-all"
                    >
                      Modifier
                    </button>
                    <div className="flex w-full gap-2">
                      <button
                        onClick={() => handleShow(job)}
                        className=" w-full p-2 flex items-center justify-center bg-gray-500 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]  hover:bg-gray-600 transition-all"
                      >
                        {window.innerWidth > 640 ? (
                          <img src={seeIcon} alt="Voir" className="h-6 w-6" />
                        ) : (
                          "Voir"
                        )}
                      </button>
                      <button
                        onClick={() => handleDelete(job.id)}
                        className=" w-full p-2 flex items-center justify-center bg-gray-500 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]  hover:bg-gray-600 transition-all"
                      >
                        {window.innerWidth > 640 ? (
                          <img
                            src={deleteIcon}
                            alt="Supprimer"
                            className="h-6 w-6"
                          />
                        ) : (
                          "Supprimer"
                        )}
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {subMenu !== "" && (
          <div className="w-full   h-full flex flex-col justify-around gap-10 col-span-2 z-30 lg:z-0 p-2 bg-black bg-opacity-50 lg:bg-transparent lg:p-0  fixed lg:relative left-0 top-0 lg:px-10 lg:py-0">
            <div className="fixed h-screen w-screen flex justify-end items-start top-0 p-5 px-7 lg:hidden  text-black"></div>
            <button
              onClick={() => (
                setSelectedId(""),
                setJobSelected(""),
                setEditId(""),
                setSubMenu("")
              )}
              className="fixed top-0 right-0 mx-7 my-5 bg-gray-500 text-white px-3 py-1 flex justify-center items-center z-50 lg:hidden  rounded hover:bg-gray-600 transition-all"
            >
              <h3>X</h3>
            </button>
            {subMenu === "add" && (
              <form
                className="flex flex-col items-center relative flex-1 justify-center rounded-xl py-10 gap-5 lg:gap-10 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] group "
                onSubmit={handleNewJobSubmit}
              >
                <h3 className="text-2xl text-center p-5 font-bold text-blue-950">
                  Ajouter un poste
                </h3>

                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="title">
                      Titre
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="employer">
                      Employeur
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      type="text"
                      name="employer"
                      value={employer}
                      onChange={(e) => setEmployer(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="location">
                      Emplacement
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      type="text"
                      name="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="salary">
                      Salaire
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      type="text"
                      name="salary"
                      value={salary}
                      onChange={(e) => setSalary(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="field">
                      Secteur
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      name="field"
                      onChange={(e) => setField(e.target.value)}
                      value={field}
                      required
                    >
                      <option value="" defaultValue={""}></option>
                      {fields.map((field, index) => (
                        <option key={index} value={field.title}>
                          {field.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="schedule">
                      Horaire
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      name="schedule"
                      onChange={(e) => setSchedule(e.target.value)}
                      value={schedule}
                      required
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="Temps partiel">Temps partiel</option>
                      <option value="Temps plein">Temps plein</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Contractuel">Contractuel</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="mode">
                      Mode
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      name="mode"
                      onChange={(e) => setMode(e.target.value)}
                      value={mode}
                      required
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="Présentiel">Présentiel</option>
                      <option value="Télétravail">Télétravail</option>
                      <option value="Permanent">Hybride</option>
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="experience">
                      Experience
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      name="experience"
                      onChange={(e) => setExperience(e.target.value)}
                      value={experience}
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="0 à 1 ans">0 à 1 ans</option>
                      <option value="1 à 3 ans">1 à 3 ans</option>
                      <option value="3 à 5 ans">3 à 5 ans</option>
                      <option value="5 et plus">5 ans et +</option>
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="language">
                      Langue(s)
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] rounded border border-gray-200 col-span-3"
                      name="language"
                      onChange={(e) => setLanguage(e.target.value)}
                      value={language}
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="Francais">Francais</option>
                      <option value="Anglais">Anglais</option>
                      <option value="Bilingue">Bilingue</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-1 w-11/12 lg:w-4/5 flex-col gap-2">
                  <label htmlFor="description"> Description </label>
                  <textarea
                    name="description"
                    className="flex-1 border border-gray-200 shadow-inner overflow-y-scroll bg-[#fafafa]"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="p-3 px-5  bg-blue-950 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-white hover:bg-blue-500 transition-all"
                >
                  Ajouter ce poste
                </button>
              </form>
            )}

            {subMenu === "edit" && (
              <form
                className="w-full h-full flex flex-col items-center flex-1 justify-center rounded-xl py-10 gap-5 lg:gap-10 z-10 lg:z-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] "
                onSubmit={handleJobPostEdit}
              >
                <div className="flex flex-col w-full ">
                  <h3 className=" text-center p-2 lg:p-5 font-bold text-blue-500">
                    Modifier un poste
                  </h3>
                  <div className="flex items-center justify-center group hover:scale-105 transition-all cursor-pointer">
                    <button>
                      <img
                        src={idIcon}
                        alt="ID"
                        className="w-10 h-10 p-2 opacity-50 transition-all group-hover:opacity-100"
                      />
                    </button>
                    <small className="text-blue-500 text-center">
                      ID: {selectedId}
                    </small>
                  </div>
                </div>

                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="title">
                      Titre
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      type="text"
                      name="editTitle"
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editEmployer">
                      Employeur
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      type="text"
                      name="editEmployer"
                      value={editEmployer}
                      onChange={(e) => setEditEmployer(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editLocation">
                      Emplacement
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      type="text"
                      name="editLocation"
                      value={editLocation}
                      onChange={(e) => setEditLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editSalary">
                      Salaire
                    </label>
                    <input
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      type="text"
                      name="editSalary"
                      value={editSalary}
                      onChange={(e) => setEditSalary(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editField">
                      Secteur
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      name="editField"
                      onChange={(e) => setEditField(e.target.value)}
                      value={editField}
                      required
                    >
                      {fields.map((field, index) => (
                        <option key={index} value={field.title}>
                          {field.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editSchedule">
                      Horaire
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      name="editSchedule"
                      onChange={(e) => setEditSchedule(e.target.value)}
                      value={editSchedule}
                      required
                    >
                      <option value="Temps partiel">Temps partiel</option>
                      <option value="Permanent">Permanent</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3  gap-2 lg:gap-10 w-11/12 lg:w-4/5 whitespace-nowrap ">
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editMode">
                      Mode
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      name="editMode"
                      onChange={(e) => setEditMode(e.target.value)}
                      value={editMode}
                      required
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="Présentiel">Présentiel</option>
                      <option value="Télétravail">Télétravail</option>
                      <option value="Hybride">Hybride</option>
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editExperience">
                      Experience
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      name="editExperience"
                      onChange={(e) => setEditExperience(e.target.value)}
                      value={editExperience}
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="0 à 1 ans">0 à 1 ans</option>
                      <option value="1 à 3 ans">1 à 3 ans</option>
                      <option value="3 à 5 ans">3 à 5 ans</option>
                      <option value="5 et plus">5 ans et +</option>
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-2 ">
                    <label className="col-span-1" htmlFor="editLanguage">
                      Langue(s)
                    </label>
                    <select
                      className="shadow-inner bg-[#fafafa] border border-gray-200 col-span-3"
                      name="editLanguage"
                      onChange={(e) => setEditLanguage(e.target.value)}
                      value={editLanguage}
                    >
                      <option value="" defaultValue={""}></option>
                      <option value="Francais">Francais</option>
                      <option value="Anglais">Anglais</option>
                      <option value="Bilingue">Bilingue</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-1 w-11/12 lg:w-4/5 flex-col gap-2 ">
                  <label htmlFor="editDescription"> Description </label>
                  <textarea
                    name="editDescription"
                    className="flex-1 border border-gray-200 shadow-inner overflow-y-scroll bg-[#fafafa]"
                    value={editDescription}
                    maxLength={1000}
                    onChange={(e) => setEditDescription(e.target.value)}
                  />
                </div>
                <div className="flex justify-around gap-10 text-white">
                  <button
                    type="submit"
                    className="p-3 px-5  bg-blue-500 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]  hover:bg-blue-950 transition-all"
                  >
                    Mettre à jour
                  </button>
                  <button
                    onClick={handleEditCancel}
                    className="p-3 px-5  bg-gray-500 rounded shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:bg-gray-800 transition-all"
                  >
                    Annuler
                  </button>
                </div>
              </form>
            )}
            {subMenu === "candidates" && (
              <Applications
                db={db}
                jobs={jobs}
                setFilterStatus={setFilterStatus}
                candidatesJob={candidatesJob}
                setCandidatesJob={setCandidatesJob}
                fetchJobs={fetchJobs}
                setSubMenu={setSubMenu}
                fetchApplications={fetchApplications}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                setAlertMessage={setAlertMessage}
              />
            )}

            {subMenu === "view" &&
              (jobSelected ? (
                <div
                  className={`w-full items-center h-full lg:pt-0 px-2 lg:px-0  flex flex-col col-span-2 relative  text-black   ${
                    window.innerWidth < 640 ? "h-full" : "h-[900px]"
                  }`}
                >
                  <div
                    className={`flex flex-col items-center w-full h-full p-5 py-10 lg:p-10 rounded-t-lg bg-[#fafafa] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-xl`}
                  >
                    <div className=" w-full  flex flex-col bg-w  lg:gap-5  ">
                      <div className="flex flex-col gap-5 lg:gap-10  lg:px-0 lg:py-10 col-span-3 ">
                        <h2 className=" font-bold text-blue-950">
                          {jobSelected.title}
                        </h2>
                        <div className=" w-full grid grid-rows-4 grid-cols-2 lg:grid-rows-2 lg:grid-cols-4 gap-2 lg:gap-10 lg:pr-10">
                          <div className=" h-full rounded-l-xl flex items-center gap-2">
                            <img
                              src={locationIcon}
                              className="w-10 h-10 p-1"
                              alt="location icon"
                            />
                            <span className="h-full  rounded-r-xl flex items-center ">
                              {jobSelected.location}
                            </span>
                          </div>

                          <div className=" h-full rounded-l-xl flex items-center gap-2 ">
                            <img
                              src={scheduleIcon}
                              className="w-10 h-10 p-1 "
                              alt="location icon"
                            />
                            <span className="h-full  rounded-r-xl flex items-center ">
                              {jobSelected.schedule}
                            </span>
                          </div>
                          {jobSelected.salary === "" ? (
                            <div className=" h-full rounded-l-xl flex items-center">
                              <img
                                src={salaryIcon}
                                className="w-10 h-10 p-1"
                                alt="location icon"
                              />
                              <span className="h-full  rounded-r-xl flex items-center ">
                                À déterminer
                              </span>
                            </div>
                          ) : (
                            <div className=" h-full rounded-l-xl flex items-center">
                              <img
                                src={salaryIcon}
                                className="w-10 h-10 p-1"
                                alt="location icon"
                              />
                              <span className="h-full  rounded-r-xl flex items-center ">
                                {jobSelected.salary}
                              </span>
                            </div>
                          )}

                          <div className=" h-full rounded-l-xl flex items-center gap-2">
                            <img
                              src={fieldIcon}
                              className="w-10 h-10 p-1"
                              alt="location icon"
                            />
                            <span className="h-full  rounded-r-xl flex items-center ">
                              {jobSelected.field}
                            </span>
                          </div>

                          {jobSelected.experience === "" ? (
                            ""
                          ) : (
                            <div className=" h-full rounded-l-xl flex items-center">
                              <img
                                src={expIcon}
                                className="w-10 h-10 p-1"
                                alt="location icon"
                              />
                              <span className="h-full  rounded-r-xl flex items-center ">
                                {jobSelected.experience}
                              </span>
                            </div>
                          )}
                          {jobSelected.mode === "" ? (
                            ""
                          ) : (
                            <div className=" h-full rounded-l-xl flex items-center">
                              <img
                                src={modeIcon}
                                className="w-10 h-10 p-1"
                                alt="location icon"
                              />
                              <span className="h-full  rounded-r-xl flex items-center ">
                                {jobSelected.mode}
                              </span>
                            </div>
                          )}
                          {jobSelected.language === "" ? (
                            ""
                          ) : (
                            <div className=" h-full rounded-l-xl flex items-center">
                              <img
                                src={languageIcon}
                                className="w-10 h-10 p-1"
                                alt="location icon"
                              />
                              <span className="h-full  rounded-r-xl flex items-center ">
                                {jobSelected.language}
                              </span>
                            </div>
                          )}

                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.href
                              );

                              setCopyOpacity("opacity-100");
                              setTimeout(
                                () => setCopyOpacity("opacity-0"),
                                3000
                              );
                            }}
                            className="flex items-center group text-blue-500 transition-all hover:scale-105"
                          >
                            <img
                              src={idIcon}
                              alt="Copier le lien de ce poste"
                              className="w-10 h-10 p-1 opacity-80 group-hover:opacity-100 transition-all"
                            />
                            <div className="flex gap-2 lg:whitespace-nowrap ">
                              <small>{selectedId}</small>
                              <small
                                className={`${copyOpacity} transition-all ease-in-out`}
                              >
                                (Copié!)
                              </small>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end items-end  w-full  px-5 pb-10"></div>
                    <hr className="lg:w-11/12 w-3/4 px-10 py-2" />
                    <div className=" flex flex-col gap-10 h-3/4 w-full  overflow-y-scroll ">
                      <div className=" lg:pr-10 rounded py-5  h-full  text-black whitespace-pre-line relative">
                        <p className="w-full">{jobSelected.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`w-full  flex flex-col col-span-2 relative text-lg rounded-xl  text-black whitespace-pre-line  bg-[#fafafa] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] ${
                    window.innerWidth < 640 ? "h-0 invisible" : "h-[1000px]"
                  }`}
                >
                  <div className="flex flex-col items-center w-full h-full p-10 rounded-t-lg "></div>
                  Choisir une option
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
