import React, { useState, useEffect } from "react";
import {
  addDoc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  setDoc,
} from "firebase/firestore";
import uuid from "react-uuid";

const Contact = ({ db, userData, setPopUpMessage }) => {
  const [contactFullName, setContactFullName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactTitle, setContactTitle] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  const handleNewMessage = async (e) => {
    e.preventDefault();
    try {
      await setDoc(
        doc(
          db,
          `${contactEmail} - messages envoyés`,
          `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De ${contactEmail} à Plan B Placement: ${contactTitle}`
        ),
        {
          id: `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De ${contactEmail} à Plan B Placement: ${contactTitle}`,
          to: "Plan B",
          title: contactTitle,
          content: contactMessage,
          from: contactEmail,
          createdAt: new Date().toDateString(),
        }
      );
      await setDoc(
        doc(
          db,
          `Plan B - messages reçues`,
          `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De ${contactEmail} à Plan B: ${contactTitle} `
        ),
        {
          id: `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De ${contactEmail} à Plan B: ${contactTitle} `,
          to: "Plan B",
          title: contactTitle,
          content: contactMessage,
          from: contactEmail,
          createdAt: new Date().toDateString(),
        }
      );
      setContactTitle("");
      setContactMessage("");
      setPopUpMessage("Message envoyé.");
    } catch (e) {
      setPopUpMessage("Message non envoyé. Erreur produite. " + e.message);
    }
  };

  useEffect(() => {
    if (userData) {
      setContactFullName(userData.firstName + " " + userData.lastName);
      setContactEmail(userData.email);
    }
  }, [userData]);

  return (
    <div className=" inset-0 w-full h-full bg-contact-img bg-img flex flex-col ">
      <div className=" flex justify-center items-center  py-20 lg:py-40 lg:bg-gradient-to-b from-transparent to-blue-950">
        <div className=" max-w-[1920px] lg:mx-auto grid lg:grid-cols-2 lg:px-40 gap-20 lg:gap-60 w-full ">
          {/* Map */}
          <div className=" rounded-lg flex flex-col gap-5 lg:gap-10 w-full px-2 lg:px-0">
            <div className="flex flex-col lg:gap-10 w-full ">
              <div className="flex flex-col bg-blue-500 rounded-lg gap-5 p-5 lg:py-8  lg:px-10 shadow-xl text-white w-full">
                <div className="flex flex-col lg:flex-row justify-between gap-5 items-center lg:items-start">
                  <div className="flex flex-col gap-1 items-center lg:items-start">
                    <h5 className=" pb-2 font-bold underline">Coordonnées</h5>
                    <p> (514) XXX - XXXX </p>
                    <p>
                      <a href="mailto:contact@planbplacement.ca">
                        contact@planbplacement.ca
                      </a>
                    </p>
                  </div>
                  <div className="flex flex-col gap-1 items-center lg:items-start">
                    <h5 className=" pb-2 font-bold underline">
                      Heures d'ouverture
                    </h5>
                    <p> Lundi au vendredi</p>
                    <p> De 8h à 20h</p>
                  </div>
                  <div className="flex flex-col gap-1 items-center lg:items-start">
                    <h5 className=" pb-2 font-bold underline">
                      Zone d'opération
                    </h5>
                    <p> Grand-Montréal </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Map */}
            <div className="relative h-[300px] lg:h-[600px] text-transparent bg-map-img bg-cover bg-white p-1 rounded-xl transition-all shadow-lg "></div>
          </div>

          {/*  Contact */}
          <div className=" h-full rounded-lg flex flex-col gap-5 lg:gap-10 w-full px-2 lg:p-0">
            <div className="flex flex-col gap-10 ">
              <div className="lg:grid lg:grid-rows-3 flex flex-col text-center bg-blue-950 rounded-lg  gap-3 p-5  shadow-xl text-white">
                <h4 className="font-bold ">
                  Des questions pour votre emploi ou entreprise?
                </h4>
                <h5> Envoyez nous un message! </h5>
                <p>Nous répondons en moins de 48 heures.</p>
              </div>
            </div>
            <form
              onSubmit={handleNewMessage}
              className="flex flex-col items-center transition-all justify-between   rounded-xl  py-10 h-full  bg-white   hover:bg-opacity-100  shadow-xl group "
            >
              <h2 className=" font-bold text-blue-950 pb-5 lg:pb-0">
                Contactez nous
              </h2>

              <div className="lg:grid lg:grid-rows-4 flex flex-col items-center gap-5 px-5 lg:px-10 lg:py-5 w-full">
                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-5 lg:gap-10 justify-between row-span-1 w-full">
                  <div className="flex flex-col  gap-1">
                    <label className="text-start">Nom complet</label>
                    <input
                      className="shadow-inner rounded w-full p-2 text-black bg-blue-100 border border-gray-200  "
                      type="text"
                      value={contactFullName}
                      onChange={(e) => setContactFullName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-start"> Courriel </label>
                    <input
                      className="shadow-inner rounded w-full p-2 text-black bg-blue-100 border border-gray-200  "
                      type="email"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-1 items-start row-span-1">
                  <label className="text-start"> Sujet </label>
                  <input
                    className="shadow-inner rounded w-full p-2 text-black bg-blue-100 border border-gray-200  "
                    type="text"
                    value={contactTitle}
                    onChange={(e) => setContactTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="flex flex-col items-center  w-full gap-1 row-span-2 h-[200px] lg:h-full">
                  <label className="self-start"> Message </label>
                  <textarea
                    className="rounded w-full flex-1 text-black  bg-blue-100 border border-gray-200  "
                    type="text"
                    size={400}
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="inline-flex shadow-xl justify-center items-center m-auto w-full px-5 py-3 text-base font-semibold no-underline align-middle hover:bg-blue-500 text-white  rounded cursor-pointer select-none bg-blue-950 transition-colors"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
