import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import downIcon from "../../media/downIcon.png";

const Navbar = ({ title, userData }) => {
  const [showNavBarList, setShowNavBarList] = useState(false);

  const [connexionLink, setConnexionLink] = useState({
    label: "Connexion",
    url: "/connexion",
  });

  const location = useLocation().pathname.substr(1);

  const navbarLinks = [
    { label: "Accueil", url: "/" },
    { label: "Emplois", url: "/emplois" },
    { label: "Employeur", url: "/employeur" },
    { label: "Contact", url: "/contact" },
  ];

  const [presentLink, setPresentLink] = useState();

  useEffect(() => {
    if (location !== "") {
      setPresentLink(
        location.split("/")[0].charAt(0).toUpperCase() +
          location.split("/")[0].slice(1)
      );
    } else {
      setPresentLink("Accueil");
    }
  }, [location]);

  useEffect(() => {
    if (userData) {
      if (userData.role === "admin") {
        setConnexionLink({
          label: "Portail admin",
          url: "/portailAdmin",
        });
      } else {
        setConnexionLink({
          label: `Mon plan B`,
          url: "/compte",
        });
      }
    } else {
      setConnexionLink({
        label: "Connexion",
        url: "/connexion",
      });
    }
  }, [userData]);

  return (
    <nav className=" w-full  z-20  text-white fixed  bg-blue-950 shadow opacity-90 hover:opacity-100 transition-all ">
      <div className="max-w-[1920px] px-5 lg:py-0 lg:px-20 mx-auto flex items-center justify-between whitespace-nowrap">
        <a href="/">
          <h2 className="tracking-wide "> {title} </h2>
        </a>
        {window.innerWidth <= 640 ? (
          <div
            className={`relative w-1/3 bg-blue-950 py-2  ${
              showNavBarList ? "rounded-t" : "rounded"
            }`}
          >
            <div
              className={`bg-blue-950 rounded-t w-full flex  border ${
                showNavBarList ? "rounded-t " : "rounded"
              }`}
            >
              <button
                onClick={() => setShowNavBarList(showNavBarList ? false : true)}
                className={`flex items-end font-bold  px-2 p-1 w-full justify-between text-white  transition-all ${
                  showNavBarList ? "rounded-t " : "rounded"
                }`}
              >
                <span className="text-center">{presentLink}</span>
                <img src={downIcon} alt="Dérouler" className="w-6 h-6" />
              </button>
            </div>

            {showNavBarList && (
              <div className="absolute flex flex-col w-full items-center text-white rounded-b bg-blue-950 border-x border-b ">
                {navbarLinks
                  .filter((navbarLink) => navbarLink.label !== presentLink)
                  .map(
                    (link, index) =>
                      link.url !== location && (
                        <div className="bg-blue-950  w-full flex  ">
                          <a
                            className={` w-full flex items-center px-1 py-2 hover:no-underline hover:bg-blue-500  hover:text-white   hover:transition-all tracking-wide 
                        
                        `}
                            href={link.url}
                            key={index}
                          >
                            <span className="text-start w-full px-2">
                              {link.label}
                            </span>
                          </a>
                        </div>
                      )
                  )}
                <div className="bg-blue-950 rounded-b-lg w-full flex justify-center">
                  <a
                    className={` hover:underline-white w-full px-1 flex items-center justify-center py-2 hover:no-underline hover:bg-blue-500  hover:text-white hover:font-bold hover:border-b hover:transition-all tracking-wide ${
                      connexionLink.url === location
                        ? "font-bold  border-b-2 border-blue-500 "
                        : ""
                    }`}
                    href={connexionLink.url}
                  >
                    <span className="text-start w-full px-2">
                      {connexionLink.label}
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex  w-1/2 justify-between   ">
            {navbarLinks.map((link, index) => (
              <a
                className={` hover:underline-white h-full flex items-start px-1 py-5 hover:no-underline hover:text-white hover:font-bold  hover:border-b-2 hover:border-blue-500 hover:transition-all tracking-wide ${
                  link.url === location
                    ? "font-bold  border-b-2 border-blue-500 "
                    : ""
                }`}
                href={link.url}
                key={index}
              >
                {link.label}
              </a>
            ))}
            <a
              className={` hover:underline-white h-full flex items-start px-1 py-5 hover:no-underline hover:text-white hover:font-bold  hover:border-b-2 hover:border-blue-500 hover:transition-all tracking-wide ${
                connexionLink.url === location
                  ? "font-bold  border-b-2 border-blue-500 "
                  : ""
              }`}
              href={connexionLink.url}
            >
              {connexionLink.label}
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
