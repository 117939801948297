import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import uuid from "react-uuid";
import emailjs from "@emailjs/browser";
import {
  addDoc,
  getDoc,
  collection,
  where,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  setDoc,
} from "firebase/firestore";
import logo from "../../media/logo.png";

const LoginPage = ({
  db,
  auth,
  setPopUpMessage,
  setAlertMessage,
  userData,
}) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailReset, setEmailReset] = useState("");

  const welcomeTitle = "Bienvenue chez Plan B Placement!";
  const welcomeContent = `
  Bonjour ${firstName} ${lastName},
  
  Nous sommes ravis de vous accueillir chez Plan B Placement! Votre compte a été créé avec succès, et vous faites désormais partie de notre communauté dynamique.
  
  Chez Plan B Placement, nous nous engageons à vous offrir les meilleures opportunités et ressources pour votre carrière. Voici quelques étapes pour bien commencer:
  
  1. Complétez votre profil: Ajoutez des informations détaillées à votre profil pour maximiser vos chances de trouver l'opportunité parfaite.
  
  2. Explorez les offres: Parcourez les dernières offres d'emploi et postulez à celles qui correspondent à vos compétences et intérêts.
  
  3. Restez connecté: Mettez régulièrement à jour votre profil et répondez rapidement aux opportunités qui vous intéressent.
  
  Si vous avez des questions ou avez besoin d'aide, n'hésitez pas à nous contacter. Nous sommes là pour vous aider!
  
  Bienvenue dans notre communauté,
  L'équipe de Plan B Placement
  `;

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!emailReset) {
      setAlertMessage("Veuillez entrer votre courriel.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, emailReset);
      setPopUpMessage(
        "Un courriel a été envoyé pour réinitialiser votre mot de passe."
      );
      setIsResettingPassword(false);
    } catch (error) {
      setAlertMessage("Ce courriel ne correspond à aucun compte.");
    }
  };

  let userId;

  const handleNewMessageWelcome = async () => {
    try {
      setDoc(
        doc(
          db,
          `messages`,
          `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De Plan B Placement à ${email}: ${welcomeTitle}`
        ),
        {
          id: `${new Date().toDateString()}-${uuid().substring(
            0,
            3
          )}- De Plan B Placement à ${email}: ${welcomeTitle}`,
          receiverEmail: email,
          senderEmail: "contact@planbplacement.com",
          title: welcomeTitle,
          from: "l'équipe de Plan B Placement",
          content: welcomeContent,
          createdAt: new Date().toDateString(),
        }
      );
      const emailParams = {
        from_name: "l'équipe de Plan B Placement",
        user_name: firstName + " " + lastName,
        user_email: email,
        message: welcomeContent,
      };
      emailjs
        .send(
          "service_xvw3uuz",
          "template_xkei86m",
          emailParams,
          "a2DeAAJMikGxw5wn6"
        )
        .then(
          (response) => {
            /* alert("Email sent successfully", response.status, response.text); */
          },
          (err) => {
            /*   alert("Failed to send email", err); */
          }
        );
    } catch (e) {
      /* alert("Message non envoyé. Erreur produite." + e); */
    }
  };

  // Sign up
  const signingUpHandler = async (e) => {
    e.preventDefault();
    if (firstName !== "" && lastName !== "") {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        userId = userCredential.user.uid;

        if (userId) {
          await createNewMemberData(userId);
          handleNewMessageWelcome();
          setPopUpMessage("Votre compte a bien été créé! Vous êtes en ligne.");
          navigate("/compte");
        }
      } catch (error) {
        console.log(error.code);
        if (error.code === "auth/invalid-email") {
          setAlertMessage("Ce courriel ne correspond à aucun compte.");
        }
        if (error.code === "auth/invalid-login-credentials") {
          setAlertMessage("Votre mot de passe ou courriel est incorrect.");
        }
        if (error.code === "auth/missing-password") {
          setAlertMessage("Veuillez entrer votre mot de passe.");
        }
      }
    } else {
      setAlertMessage("Tous les champs ne sont pas remplis.");
    }
  };

  // Adding member to database
  const createNewMemberData = async (userId) => {
    try {
      await setDoc(doc(db, "membres", userId), {
        id: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        status: "Nouveau candidat",
        role: "user",
        createdAt: new Date().toDateString(),
      });
      setPopUpMessage("Votre compte a bien été créé!");
    } catch (error) {
      setPopUpMessage(
        "Une erreur s'est produire lors de la création de compte, veuillez reéssayez. " +
          error.message
      );
      navigate("/connexion");
    }
  };

  // Login
  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      userId = userCredential.user.uid;
      setPopUpMessage("Vous êtes en ligne.");
    } catch (error) {
      if (error.code === "auth/invalid-login-credentials") {
        setAlertMessage("Votre mot de passe ou courriel est incorrect.");
      }
      if (error.code === "auth/auth/weak-password") {
        setAlertMessage(
          "Votre mot de passe doit contenir au moins 6 caractères."
        );
      }
    }
  };

  useEffect(() => {
    if (userData.role === "admin") {
      navigate("/portailAdmin");
    } else if (userData.role === "user") {
      navigate("/compte");
    } else {
      navigate("/connexion");
    }
  }, [userData]);

  return (
    <main className="flex flex-col gap-5 h-full ">
      {/*  Lead Image */}

      <div className="w-full h-full lg:bg-connexion-img bg-img ">
        <div className=" flex flex-col lg:px-20 py-20  justify-start items-center lg:bg-gradient-to-t from-blue-950 to-transparent">
          <img
            className="lg:h-72 lg:w-72 w-40 h-40 p-10"
            src={logo}
            alt="Icon Entreprise"
          />
          <div className="w-full h-full px-2 lg:px-40 max-w-[1920px] lg:mx-auto">
            <div className=" w-full gap-10 lg:gap-20  flex flex-col-reverse lg:grid lg:grid-cols-2  justify-center items-center  h-full transition-all ">
              <form
                onSubmit={signingUpHandler}
                className="flex flex-col items-center transition-all justify-start w-full  gap-10 h-full py-12 bg-white p-5 rounded-xl  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] group "
              >
                <h2 className=" text-center text-blue-950">Créer un compte</h2>
                <div className="flex flex-col justify-center items-center   w-full lg:w-4/5  lg:pr-2 gap-5">
                  <div className="flex w-full justify-between gap-5 lg:gap-10 text-lg items-center ">
                    <div className="flex flex-col gap-2 w-1/2">
                      <label
                        htmlFor="firstName"
                        className="text-start text-black"
                      >
                        Prénom
                      </label>
                      <input
                        className=" shadow-inner bg-blue-100 rounded  border border-gray-200 "
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-1/2">
                      <label
                        htmlFor="lastName"
                        className="text-start text-black"
                      >
                        Nom
                      </label>
                      <input
                        className=" shadow-inner bg-blue-100 rounded  border border-gray-200 mr-4 lg:mr-0 "
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    <label
                      htmlFor="email"
                      className="col-span-1 text-end text-black h-full flex items-center"
                    >
                      Courriel
                    </label>
                    <input
                      className=" shadow-inner bg-blue-100 rounded  border border-gray-200 "
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    <label
                      htmlFor="password"
                      className="col-span-1 text-end text-black h-full flex items-center"
                    >
                      Mot de passe
                    </label>
                    <input
                      className=" shadow-inner bg-blue-100 rounded  border border-gray-200 "
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <button
                    type="submit"
                    className="p-3 rounded w-full bg-blue-900 hover:bg-blue-950 transition-all text-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                  >
                    Inscription
                  </button>
                </div>
              </form>
              <div className="relative bg-white w-full h-full p-5 rounded-xl  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                <form
                  onSubmit={loginHandler}
                  className="flex flex-col items-center transition-all justify-start w-full  gap-10 h-full py-10   group "
                >
                  <h2 className=" text-center text-blue-500">Se connecter</h2>
                  <div className="grid grid-rows-2 items-center  px-5 w-full lg:w-4/5 flex-1 gap-5">
                    <div className="flex flex-col gap-2 w-full justify-between text-lg items-center ">
                      <label
                        className="w-full text-start text-black h-full flex justify-start items-center"
                        htmlFor="email"
                      >
                        Courriel
                      </label>
                      <input
                        className=" shadow-inner bg-blue-100 rounded w-full border border-gray-200 "
                        type="email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full justify-between text-lg items-center ">
                      <label
                        className="w-full text-start text-black h-full flex justify-start items-center"
                        htmlFor="password"
                      >
                        Mot de passe
                      </label>
                      <input
                        className=" shadow-inner bg-blue-100 rounded w-full border border-gray-200 "
                        type="password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                    </div>

                    <button
                      onClick={loginHandler}
                      className="p-3 rounded w-full bg-blue-500 group transition-all hover:bg-blue-600 text-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                    >
                      <span>Connexion</span>
                    </button>
                  </div>
                </form>
                <div className="absolute bottom-0 right-0 p-2 px-10 justify-end  ">
                  <button onClick={() => setIsResettingPassword(true)}>
                    Mot de passe oublié ?
                  </button>
                </div>
              </div>
              {isResettingPassword && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                  <form
                    onSubmit={handleResetPassword}
                    className="bg-white p-10 rounded-xl flex flex-col gap-5"
                  >
                    <p className="font-bold">
                      Entrez le ce courriel de votre compte pour vous
                      réinitialiser votre mot de passe.
                    </p>
                    <input
                      type="email"
                      value={emailReset}
                      onChange={(e) => setEmailReset(e.target.value)}
                      className=" shadow-inner bg-blue-100 rounded w-full border border-gray-200 "
                      required
                    />
                    <div className="flex w-full justify-between lg:whitespace-nowrap gap-10">
                      <button
                        type="submit"
                        className="w-1/2 bg-gray-100 hover:bg-gray-300 p-1 rounded-  shadow transition-all"
                      >
                        Réinitialiser le mot de passe
                      </button>
                      <button
                        onClick={() => setIsResettingPassword(false)}
                        className="w-1/2 bg-gray-100 hover:bg-gray-300 p-1 rounded-  shadow transition-all"
                      >
                        Annuler
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
