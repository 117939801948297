import React, { useState, useEffect, useMemo } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { db, auth } from "./firebase";
import { signOut } from "firebase/auth";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  limit,
  doc,
  where,
  onSnapshot,
} from "firebase/firestore";
import Navbar from "./components/UI/Navbar";
import Home from "./components/Home";
import Jobs from "./components/candidates/Jobs";
import Footer from "./components/UI/Footer";
import Portal from "./components/admin/Portal";
import EmployerHub from "./components/employers/EmployerHub";
import LoginPage from "./components/login/LoginPage";
import Contact from "./components/UX/Contact";
import Account from "./components/UX/Account";
import Application from "./components/candidates/Application";
import ScrollToTop from "./components/UI/ScrollToTop";

import techImage from "./media/techImage.jpg";
import educationImage from "./media/educationImage.jpg";
import logisticImage from "./media/logisticImage.jpg";
import engineeringImage from "./media/engineeringImage.jpg";
import industrielImage from "./media/industrielImage.jpg";
import manutentionImage from "./media/manutentionImage.jpg";
import administrationImage from "./media/administrationImage.jpg";
import checkIcon from "./media/checkIcon.png";
import alertIcon from "./media/alertIcon.png";

function App() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");

  const [jobs, setJobs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [selectedField, setSelectedField] = useState();
  const [scrollToJobs, setScrollToJobs] = useState(false);
  const [isUserConnected, setIsUserConnected] = useState(false);

  const [popUpMessage, setPopUpMessage] = useState("");
  const [popUpOpacity, setPopUpOpacity] = useState("opacity-0");

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpacity, setAlertOpacity] = useState("opacity-0");

  useEffect(() => {
    if (popUpMessage !== "") {
      setTimeout(() => {
        setPopUpOpacity("opacity-25");
      }, 100);

      setTimeout(() => {
        setPopUpOpacity("opacity-50");
      }, 200);

      setTimeout(() => {
        setPopUpOpacity("opacity-75");
      }, 300);

      setTimeout(() => {
        setPopUpOpacity("opacity-100");
      }, 400);

      setTimeout(() => {
        setPopUpOpacity("opacity-75");
      }, 3000);

      setTimeout(() => {
        setPopUpOpacity("opacity-50");
      }, 3100);

      setTimeout(() => {
        setPopUpOpacity("opacity-25");
      }, 3200);

      setTimeout(() => {
        setPopUpOpacity("opacity-0");
      }, 3300);

      setTimeout(() => {
        setPopUpMessage("");
      }, 3300);
    }
  }, [popUpMessage]);

  useEffect(() => {
    if (alertMessage !== "") {
      setTimeout(() => {
        setAlertOpacity("opacity-25");
      }, 100);

      setTimeout(() => {
        setAlertOpacity("opacity-50");
      }, 200);

      setTimeout(() => {
        setAlertOpacity("opacity-75");
      }, 300);

      setTimeout(() => {
        setAlertOpacity("opacity-100");
      }, 400);

      setTimeout(() => {
        setAlertOpacity("opacity-75");
      }, 3000);

      setTimeout(() => {
        setAlertOpacity("opacity-50");
      }, 3100);

      setTimeout(() => {
        setAlertOpacity("opacity-25");
      }, 3200);

      setTimeout(() => {
        setAlertOpacity("opacity-0");
      }, 3300);

      setTimeout(() => {
        setAlertMessage("");
      }, 3300);
    }
  }, [alertMessage]);

  const [authChecking, setAuthChecking] = useState(true);

  const fieldsList = [
    {
      title: "Technologies et information",
      picture: techImage,
    },
    {
      title: "Éducation",
      picture: educationImage,
    },
    {
      title: "Transport et logistique",
      picture: logisticImage,
    },
    {
      title: "Ingénierie",
      picture: engineeringImage,
    },
    {
      title: "Manutention",
      picture: manutentionImage,
    },
    {
      title: "Services administratifs",
      picture: administrationImage,
    },
    {
      title: "Industriel",
      picture: industrielImage,
    },
  ];

  const fetchJobs = async () => {
    try {
      const jobsQuery = query(
        collection(db, "postes"),
        orderBy("createdAt", "desc")
      );
      const jobsSnapshot = await getDocs(jobsQuery);
      const jobsList = jobsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setJobs(jobsList);

      const locationsList = jobsList.map((job) => job.location);
      const uniquesLocations = [...new Set(locationsList)].filter(
        (location) => location !== ""
      );
      setLocations(uniquesLocations);
    } catch (err) {
      setError(err);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  // Member fetch if connected
  const fetchDataOnConnection = async (user) => {
    if (!user) return;
    try {
      const userDoc = doc(db, "membres", user.uid);
      const docSnapshot = await getDoc(userDoc);
      if (docSnapshot.exists()) {
        const userDataFetched = docSnapshot.data();
        console.log(userDataFetched);
        setUserData(userDataFetched);
        setUserEmail(userDataFetched.email);
        setUserFirstName(userDataFetched.firstName);
        setUserLastName(userDataFetched.lastName);
        if (userDataFetched.role === "admin") setUserRole("admin");
        console.log("Membre connecté: " + userDataFetched.email);
      }
    } catch (e) {
      console.log("Membre non trouvé." + e.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setAuthChecking(true);
      if (currentUser) {
        fetchDataOnConnection(currentUser);
        setIsUserConnected(true);
      } else {
        setIsUserConnected(false);
        setUserData("");
        setUserFirstName("");
        setUserLastName("");
        setUserEmail("");
      }
      setAuthChecking(false);
    });

    return unsubscribe;
  }, []);

  const reloadUserData = async () => {
    await fetchDataOnConnection(userData);
    console.log("data reloaded for user " + userData.savedJobs);
  };

  useEffect(() => {
    if (isUserConnected && userData) {
      setUserEmail(userData.email);
      const userRef = doc(db, "membres", userData.id);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        const updatedUserData = doc.data();
        // Update local state
        fetchDataOnConnection(updatedUserData);
      });
      // Clean up the subscription
      return () => unsubscribe();
    }
  }, [isUserConnected, userData]);

  return (
    <div className="w-full relative">
      <Navbar
        title="Plan B"
        userData={userData}
        reloadUserData={reloadUserData}
      />
      <ScrollToTop />
      {popUpMessage !== "" && (
        <div
          className={`fixed z-50 top-20 w-full flex justify-center transition-all ${
            popUpMessage !== "" ? popUpOpacity : "opacity-0"
          }`}
        >
          <div className="flex gap-2 items-center p-3 px-5 rounded shadow-xl bg-white">
            <img src={checkIcon} className="w-7 h-7" />
            <h5> {popUpMessage} </h5>
          </div>
        </div>
      )}

      {alertMessage !== "" && (
        <div
          className={`fixed z-50 top-20 w-full flex justify-center transition-all ${
            alertMessage !== "" ? alertOpacity : "opacity-0"
          }`}
        >
          <div className="flex gap-2 items-center p-3 px-5 rounded shadow-xl bg-white">
            <img src={alertIcon} className="w-7 h-7" />
            <h5> {alertMessage} </h5>
          </div>
        </div>
      )}

      <Routes>
        <Route
          index
          element={
            <Home
              db={db}
              jobs={jobs}
              fields={fieldsList}
              userData={userData}
              setSelectedField={setSelectedField}
              setScrollToJobs={setScrollToJobs}
              setPopUpMessage={setPopUpMessage}
              setAlertMessage={setAlertMessage}
            />
          }
        />
        <Route
          path="emplois/"
          element={
            <Jobs
              jobs={jobs}
              fields={fieldsList}
              locations={locations}
              selectedField={selectedField}
              setSelectedField={setSelectedField}
              userData={userData}
              setScrollToJobs={setScrollToJobs}
              scrollToJobs={scrollToJobs}
              setPopUpMessage={setPopUpMessage}
              reloadUserData={reloadUserData}
              setAlertMessage={setAlertMessage}
            />
          }
        />
        <Route
          path="application/:jobId"
          element={
            <Application
              jobs={jobs}
              userData={userData}
              setPopUpMessage={setPopUpMessage}
              setScrollToJobs={setScrollToJobs}
              reloadUserData={reloadUserData}
              setAlertMessage={setAlertMessage}
            />
          }
        />
        <Route
          path="connexion"
          element={
            <LoginPage
              fields={fieldsList}
              db={db}
              auth={auth}
              setPopUpMessage={setPopUpMessage}
              setAlertMessage={setAlertMessage}
              userData={userData}
            />
          }
        />
        <Route
          path="employeur"
          element={
            <EmployerHub
              db={db}
              userData={userData}
              setPopUpMessage={setPopUpMessage}
              setAlertMessage={setAlertMessage}
            />
          }
        />
        <Route
          path="contact"
          element={
            <Contact
              db={db}
              userData={userData}
              setPopUpMessage={setPopUpMessage}
              setAlertMessage={setAlertMessage}
            />
          }
        />
        <Route
          path="compte"
          element={
            userData ? (
              <Account
                userData={userData}
                setPopUpMessage={setPopUpMessage}
                setScrollToJobs={setScrollToJobs}
                reloadUserData={reloadUserData}
                setAlertMessage={setAlertMessage}
              />
            ) : (
              <Navigate to="/connexion" />
            )
          }
        />
        <Route
          path="portailAdmin"
          element={
            userData && userRole === "admin" ? (
              <Portal
                jobs={jobs}
                userData={userData}
                fetchJobs={fetchJobs}
                fields={fieldsList}
                locations={locations}
                setPopUpMessage={setPopUpMessage}
                setAlertMessage={setAlertMessage}
              />
            ) : (
              ""
            )
          }
        />
      </Routes>
      <Footer
        title="Plan B"
        fields={fieldsList}
        isLoaded={loaded}
        jobs={jobs}
        setSelectedField={setSelectedField}
        reloadUserData={reloadUserData}
      />
    </div>
  );
}

export default App;
