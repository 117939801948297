import React, { useState, useEffect } from "react";
import {
  addDoc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  where,
  setDoc,
} from "firebase/firestore";
import emailjs from "@emailjs/browser";
import uuid from "react-uuid";
import locationIcon from "../../media/locationIcon.png";
import fieldIcon from "../../media/fieldIcon.png";
import scheduleIcon from "../../media/scheduleIcon.png";
import salaryIcon from "../../media/salaryIcon.png";
import idIcon from "../../media/idIcon.png";
import addIcon from "../../media/addIcon.png";
import refreshIcon from "../../media/refreshIcon.png";
import seeIcon from "../../media/seeIcon.png";
import downloadIcon from "../../media/downloadIcon.png";

const Messages = ({
  db,
  membersList,
  setNewEmailInput,
  setNewMessageTitle,
  newMessageTitle,
  newMemberEmail,
  newEmailInput,
  setNewMemberEmail,
  userData,
  setAlertMessage,
}) => {
  const [sentMessagesList, setSentMessagesList] = useState([]);
  const [receivedMessagesList, setReceivedMessagesList] = useState([]);
  const [messageSelected, setMessageSelected] = useState("");

  const [newMessageTo, setNewMessageTo] = useState([]);
  const [newMessageContent, setNewMessageContent] = useState("");

  const [messagingStatus, setMessagingStatus] = useState("");

  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterMessageType, setfilterMessageType] = useState("sent");
  const [filterMessagesByMember, setFilterMessagesByMember] =
    useState("Tous les membres");
  const [filteredMessages, setFilteredMessages] = useState([]);

  const fetchMessagesSent = async () => {
    const messagesQuery = query(
      collection(db, `messages`),
      where("senderEmail", "==", "contact@planbplacement.com"),
      orderBy("createdAt", "asc")
    );
    const querySnapshot = await getDocs(messagesQuery);

    const updatedMessagesList = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setSentMessagesList(updatedMessagesList);
  };

  const fetchMessagesReceived = async () => {
    const messagesQuery = query(
      collection(db, `messages`),
      where("receiverEmail", "==", "contact@planbplacement.com"),
      orderBy("createdAt", "asc")
    );
    const querySnapshot = await getDocs(messagesQuery);

    const updatedMessagesList = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setReceivedMessagesList(updatedMessagesList);
  };

  const handleNewMessage = async (e) => {
    e.preventDefault();
    console.log(newMessageTo);
    newMessageTo.forEach(async (newMessageEmail) => {
      console.log(newMessageEmail);
      try {
        await setDoc(
          doc(
            db,
            "messages",
            `${new Date().toDateString()}-${uuid().substring(
              0,
              3
            )}- De Plan B Placement à ${newMessageEmail}: ${newMessageTitle} `
          ),
          {
            id: `${new Date().toDateString()}-${uuid().substring(
              0,
              3
            )}- De Plan B Placement à ${newMessageEmail}: ${newMessageTitle} `,
            senderEmail: "contact@planbplacement.com",
            receiverEmail: newMessageEmail,
            title: newMessageTitle,
            from: "L'équipe de Plan B Placement",
            content: newMessageContent,
            createdAt: new Date().toDateString(),
          }
        );
        const emailParams = {
          from_name: "l'équipe de Plan B Placement",
          user_email: newMessageEmail,
          message: newMessageContent,
        };
        emailjs
          .send(
            "service_xvw3uuz",
            "template_xkei86m",
            emailParams,
            "a2DeAAJMikGxw5wn6"
          )
          .then(
            (response) => {
              /*alert("Email sent successfully", response.status, response.text);*/
            },
            (err) => {
              /* alert("Failed to send email", err);*/
            }
          );
        setNewMessageTitle("");
        setNewMessageContent("");
        setNewMessageTo([]);
      } catch (e) {
        setAlertMessage("Message non envoyé. Erreur produite." + e);
      }
    });
  };

  useEffect(() => {
    if (newMemberEmail === "email") {
      setNewMessageTo([newEmailInput]);
      console.log(newEmailInput);
    } else if (newMemberEmail === "Tous les membres") {
      setNewMessageTo(membersList.map((member) => member.email));
    } else {
      setNewMessageTo([newMemberEmail]);
    }
  }, [newMemberEmail]);

  const handleFilterKeywordChange = (e) => {
    setFilterKeyword(e.target.value);
  };

  const handleFilterMessageByType = (e) => {
    setfilterMessageType(e.target.value);
  };

  const handleFilterMessagesByMember = (e) => {
    setFilterMessagesByMember(e.target.value);
  };

  const handleRefresh = () => {
    setFilterKeyword("");
    setFilterMessagesByMember("");
    setfilterMessageType("sent");
    setMessageSelected("");
    fetchMessagesReceived();
    fetchMessagesSent();
  };

  const applyFilter = () => {
    let filtered;
    if (filterMessageType === "received") {
      filtered = receivedMessagesList;
    } else {
      filtered = sentMessagesList;
    }
    if (filtered.length > 0) {
      if (filterKeyword.length > 0) {
        filtered = filtered.filter(
          (message) =>
            message.title.toLowerCase().includes(filterKeyword.toLowerCase()) ||
            message.content
              .toLowerCase()
              .includes(filterKeyword.toLowerCase()) ||
            message.from.toLowerCase().includes(filterKeyword.toLowerCase())
        );
      }
      if (filterMessagesByMember !== "Tous les membres") {
        filtered = filtered.filter(
          (message) =>
            message.senderEmail.includes(filterMessagesByMember) ||
            message.from.includes(filterMessagesByMember)
        );
      }
    }

    setFilteredMessages(filtered);
  };

  useEffect(() => {
    applyFilter();
  }, [
    filterKeyword,
    filterMessageType,
    filterMessagesByMember,
    sentMessagesList.length,
    receivedMessagesList.length,
  ]);

  useEffect(() => {
    fetchMessagesReceived();
    fetchMessagesSent();
  }, []);

  console.log(sentMessagesList);
  console.log(receivedMessagesList);
  console.log(filteredMessages);

  return (
    <div className="flex flex-col gap-5">
      {/* messages filter */}
      <div className="flex justify-around  gap-10 bg-blue-500 shadow-inner rounded-lg p-6 ">
        <div className="flex flex-col lg:grid lg:grid-cols-5 gap-2 lg:gap-10 flex-1 ">
          <input
            placeholder="Sujet, mots clés"
            className="w-full flex gap-5 text-center justify-around items-center whitespace-nowrap shadow-xl rounded-md py-2 border border-gray-100 "
            value={filterKeyword}
            onChange={(e) => handleFilterKeywordChange(e)}
          ></input>
          <select
            className="w-full flex gap-5  justify-around items-center whitespace-nowrap shadow-xl rounded-md py-2 border border-gray-100 "
            onChange={(e) => handleFilterMessagesByMember(e)}
            value={filterMessagesByMember}
          >
            <option
              value="Tous les membres"
              className="relative flex flex-col shadow-xl  rounded hover:scale-105 group transition-all cursor-pointer"
            >
              Tous les membres
            </option>
            {membersList
              ? membersList.map((member, index) => {
                  return (
                    <option
                      key={index}
                      value={member.email}
                      className="relative flex flex-col shadow-xl  rounded hover:scale-105 group transition-all cursor-pointer"
                    >
                      {member.firstName} {member.lastName} ({member.email})
                    </option>
                  );
                })
              : ""}
          </select>
          <select
            className="w-full flex gap-5  justify-around items-center whitespace-nowrap shadow-xl rounded-md py-2 border border-gray-100 "
            value={filterMessageType}
            onChange={(e) => handleFilterMessageByType(e)}
          >
            <option value="received">Messages reçues</option>
            <option value="sent" defaultValue>
              Messages envoyés
            </option>
          </select>
          <div className="w-full flex justify-start">
            <button
              className="group  w-full flex hover:scale-110 justify-center items-center whitespace-nowrap shadow-xl px-5 rounded-md bg-blue-950 text-white hover:shadow-none  transition-all"
              onClick={handleRefresh}
            >
              Rafraichir
              <img
                className="h-8 w-8 p-1 scale-75 group-hover:scale-100 transition-all "
                src={refreshIcon}
                alt="Rafraichir"
              />
            </button>
          </div>
          <div className="w-full flex justify-end">
            <button
              className="group  w-full flex hover:scale-110 justify-center items-center whitespace-nowrap shadow-xl px-5 rounded-md bg-blue-950 text-white hover:shadow-none  transition-all"
              onClick={() => {
                setMessagingStatus("adding");
                setMessageSelected("");
              }}
            >
              Écrire un message
              <img
                className="h-8 w-8 p-1 scale-75 group-hover:scale-100 transition-all "
                src={addIcon}
                alt="Ajouter un email"
              />
            </button>
          </div>
        </div>
      </div>
      <div className=" w-full h-full gap-10 bg-black bg-opacity-10  shadow-inner rounded-lg flex flex-col lg:grid lg:grid-cols-3 p-2 lg:p-10 ">
        <ul className="flex flex-col h-[800px] gap-5  overflow-y-scroll  ">
          {/* Messages List */}
          {filteredMessages
            ? filteredMessages.map((message, index) => (
                <li
                  key={index}
                  className={`rounded-lg group mr-2 lg:mr-10 bg-[#fafafa] shadow-xl transition-all  ${
                    messageSelected === message ? "scale-100 " : "scale-95"
                  } hover:scale-100 transition-all`}
                >
                  <div
                    onClick={() => {
                      setMessageSelected(message);
                      setMessagingStatus("viewing");
                    }}
                    className="  flex flex-col justify-between w-full h-full rounded-lg cursor-pointer "
                  >
                    <div className=" relative flex flex-col p-5 py-10    ">
                      <div className="flex flex-1 flex-col gap-5 pb-5 justify-between">
                        <h5
                          className={` group-hover:text-blue-950 font-bold ${
                            messageSelected === message
                              ? "text-blue-950"
                              : "text-blue-500"
                          }`}
                        >
                          {message.title}
                        </h5>
                      </div>
                      <hr />
                      <p className="text-justify pt-5 px-2 ">
                        {message.content.slice(0, 100)}...
                      </p>
                    </div>

                    <div
                      className={` p-2 right-0 w-full flex items-center justify-between text-white
                   rounded-b-lg group-hover:bg-blue-950 transition-all ${
                     messageSelected === message ? "bg-blue-950" : "bg-blue-500"
                   }`}
                    >
                      <small> {message.createdAt.slice(3)}</small>
                      <small> De {message.from}</small>
                    </div>
                  </div>
                </li>
              ))
            : ""}
        </ul>

        {/* New message */}
        {messagingStatus !== "" || messageSelected !== "" ? (
          <div
            className={`col-span-2 ${
              window.innerWidth <= 640
                ? "fixed w-full h-full top-0 left-0 z-30 p-2  bg-black bg-opacity-50 "
                : "flex flex-col relative z-0"
            }`}
          >
            <div className="fixed h-screen w-screen flex justify-end items-start top-0 p-5 px-7 lg:hidden  text-black"></div>
            <button
              onClick={() => (setMessagingStatus(""), setMessageSelected(""))}
              className="fixed top-0 right-0 mx-7 my-5 bg-gray-500 text-white px-3 py-1 flex justify-center items-center z-50 lg:hidden  rounded hover:bg-gray-600 transition-all"
            >
              <h3>X</h3>
            </button>
            <div className="bg-[#fafafa] shadow-xl rounded-lg whitespace-pre-linetext-black h-full ">
              {messagingStatus === "adding" ? (
                <form
                  onSubmit={handleNewMessage}
                  className="flex flex-col items-center w-full h-full p-5 lg:p-10 rounded-t-lg "
                >
                  <div className=" w-full h- flex flex-col   gap-5  z-10 lg:z-0">
                    <div className="flex flex-col gap-10 py-10 ">
                      <div className="flex items-start lg:items-center flex-col lg:flex-row">
                        <label className="w-1/12 flex whitespace-nowrap ">
                          À :
                        </label>
                        <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 w-full">
                          <input
                            className=" shadow-inner rounded p-2 lg:w-1/2 text-black bg-[#fafafa] border border-gray-200 "
                            type="email"
                            onChange={(e) => setNewEmailInput(e.target.value)}
                            value={
                              newMemberEmail === "email"
                                ? newEmailInput
                                : newMemberEmail
                            }
                            {...(newMemberEmail === "Tous les membres"
                              ? {
                                  type: "text",
                                }
                              : " ")}
                          ></input>

                          <select
                            onChange={(e) => {
                              setNewMemberEmail(e.target.value);
                            }}
                            className="bg-[#fafafa] rounded  p-2 border shadow-inner border-gray-200 flex-1"
                          >
                            <option
                              value="email"
                              className="relative flex flex-col shadow-xl  rounded hover:scale-105 group transition-all cursor-pointer"
                            >
                              Email personnalisée
                            </option>
                            <option
                              value="Tous les membres"
                              className="relative flex flex-col shadow-xl  rounded hover:scale-105 group transition-all cursor-pointer"
                            >
                              Tous les membres
                            </option>
                            {membersList.map((member, index) => {
                              return (
                                <option
                                  key={index}
                                  value={member.email}
                                  className="relative flex flex-col shadow-xl  rounded hover:scale-105 group transition-all cursor-pointer"
                                >
                                  {member.firstName} {member.lastName} (
                                  {member.email})
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="flex lg:flex-row lg:items-center flex-col items-start">
                        <label className="w-1/12  whitespace-nowrap ">
                          Sujet :
                        </label>
                        <input
                          className=" shadow-inner rounded p-2 w-full text-black bg-[#fafafa] border border-gray-200"
                          type="text"
                          value={newMessageTitle}
                          onChange={(e) => {
                            setNewMessageTitle(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-end    px-5 ">
                    <div className="flex  items-end col-span-1 justify-start gap-5 h-full w-1/2"></div>
                  </div>
                  <hr className="w-11/12 px-10 py-2" />
                  <div className=" flex flex-col gap-10 h-full w-full  ">
                    <div className=" rounded pt-5  h-full  text-black whitespace-pre-line relative">
                      <textarea
                        value={newMessageContent}
                        placeholder="Écrire un message..."
                        onChange={(e) => {
                          setNewMessageContent(e.target.value);
                        }}
                        className="h-full w-full overflow-y-scroll bg-[#fafafa] border border-gray-200 shadow-inner focus:outline-none "
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="mt-10 z-10 lg:z-0 w-fit inline-flex shadow-xl hover:shadow-none justify-center items-center m-auto px-10 py-3 text-base font-semibold no-underline align-middle hover:bg-blue-500 text-white  rounded cursor-pointer select-none bg-blue-950 transition-colors"
                  >
                    Envoyer
                  </button>
                </form>
              ) : (
                <div className=" w-full h-full lg:h-[800px] flex flex-col col-span-2 relative text-lg rounded-lg  text-black whitespace-pre-line  bg-[#fafafa] pb-5 shadow-xl">
                  {messageSelected && (
                    <div className="flex flex-col items-center w-full h-full p-5 lg:p-10 rounded-t-lg ">
                      <div className=" w-full h- flex flex-col gap-2  lg:gap-5  ">
                        <div className="flex flex-col gap-5 lg:gap-10 py-5 lg:py-10 col-span-3 ">
                          <h2 className=" font-bold text-blue-950">
                            {messageSelected.title}
                          </h2>
                        </div>
                        <div className=" flex justify-between w-full">
                          <span>
                            Envoyé le {messageSelected.createdAt.slice(3)}
                          </span>
                          <span className="text-end">
                            De {messageSelected.from}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end items-end  w-full  px-5 pb-5 lg:pb-10">
                        <div className="flex  items-end col-span-1 justify-start gap-5 w-1/2"></div>
                      </div>
                      <hr className="w-11/12 lg:px-10 py-2" />
                      <div className=" flex flex-col gap-10 h-3/4 w-full  overflow-y-scroll">
                        <div className="pr-5 lg:pr-10 rounded lg:py-5  h-full  text-black whitespace-pre-line relative">
                          {messageSelected.content}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Messages;
